var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
var site = site || {};

site.util = site.util || {};
site.util.refreshSelectBoxes = site.util.refreshSelectBoxes || function () {};

(function ($) {
  $(document).on('change', '.js-sku-menu', function () {
    var $skuMenu = $(this);
    var selectedSkuBaseId = $skuMenu.val();
    var $productCtaBtn = $('.js-product-cta');

    prodcat.ui.getProductEl($skuMenu).attr('data-sku-base-id', selectedSkuBaseId).trigger('product.skuSelect', selectedSkuBaseId);
    if ($productCtaBtn.length > 0) {
      $productCtaBtn.data('sku-base-id', selectedSkuBaseId);
    }
  });

  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    skuSelectedId = skuBaseId;
    var $menu = $('.js-sku-menu', this);

    $menu.val(skuBaseId);
    site.util.refreshSelectBoxes($menu);
  });

  Drupal.behaviors.skuMenuV1 = {
    attach: function (context) {
      var self = this;
      var $skuMenu = $('select.js-sku-menu', context);

      if ($skuMenu.length < 1) {
        return null;
      }
      self.init($skuMenu);
    },

    init: function ($skuMenu) {
      var prodId = $skuMenu.data('product-id');

      // Multi-Product Palette
      // set bgcolors of swatches to 1st Shade from 1st SKU of each Product
      if (prodcat.data.isPaletteMultiProduct(prodId)) {
        $skuMenu.empty();
        var prods = prodcat.data.getPaletteMultiProducts();

        _.map(prods, function (prod, idx) {
          var sku = prod['skus'][0];

          if ($('.js-swatch-list-item').hasClass('js-default-sku-select')) {
            var skuDefault = $('.js-default-sku-select').data('sku-base-id');

            sku = prodcat.data.getSku(skuDefault);
          }
          var optionHtml = "<option class='js-sku' ";

          optionHtml += "value='" + sku.SKU_BASE_ID + "' ";
          optionHtml += "data-sku-base-id='" + sku.SKU_BASE_ID + "' ";
          optionHtml += "data-product-id='" + sku.PRODUCT_ID + "'>";
          optionHtml += sku.shadeMenuLabel;
          optionHtml += '</option>';
          $skuMenu.append(optionHtml);
        });
        if (!!$skuMenu.data('selectBoxControl')) {
          $skuMenu.selectBox('refresh');
        }
      }
    }
  };
})(jQuery);
